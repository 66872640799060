import { Env } from 'src/app/enums/environment.enum';

export const environment = {
  id: Env.TEST,
  production: true,
  baseUrls: {
    httpLoaderPrefix: './assets/i18n/',
    httpLoaderSuffix: '.json',
    apiBaseUrl: 'https://dwto660dn7-vpce-09b66c79525ffc5e3.execute-api.eu-central-1.amazonaws.com/noprod-test/backend/',
    authenticationUrl: {
      base: 'https://auth-noprod-test.auth.eu-central-1.amazoncognito.com/oauth2/authorize?',
      options: {
        identity_provider: 'Azure',
        response_type: 'TOKEN',
        scope: 'aws.cognito.signin.user.admin+email+openid+profile',
        redirect_uri: window.location.protocol + '//' + window.location.host, //'https://app.noprod-test.pdp.alperia.digital'
      }
    },
    refreshTokenUrl: {
      base: 'https://auth-noprod-test.auth.eu-central-1.amazoncognito.com/oauth2/token'
    }
  },
};